import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'

const PriceListSection = loadable(() => pMinDelay(import('./PriceListSection'), 1000), {
  fallback: <div className='bg-red-900 pt-6 pb-12'><p className='text-2xl italic text-white font-bold text-center'>Loading Request Form...</p></div>,
})

const PriceListDescSection = () => (
  <div className='bg-red-900 py-12'>
    <div className='w-11/12 max-w-screen-lg mx-auto'>
      <h1 className='text-white text-2xl md:text-4xl text-center font-bold'>Command Access will publish a new Price List on 1/3/23 to kick off New Year.</h1>
      <div className='w-5/12 border border-b-2 border-white mx-auto mt-10 mb-6'></div>
      <p className='text-white font-semibold mb-4'>Over the past few years, keeping our pricing consistent for our customers has been challenging. But we've worked hard & being an American manufacturer enabled us to keep it to only one minimal increase, forgoing any surcharges. Unfortunately, there are no signs that these costs will come back down. If anything, many main factories have announced 2-3 price increases/per year on mechanical products that we modify.</p>
      <p className='text-white font-semibold mb-10'>We will continue to provide our customers with our best lead times possible & competitive pricing, instituting a minimal increase varying per product category.</p>
      <p className='text-white font-semibold mb-6'>Here's the average increase.</p>

      <StaticImage
        src="../../images/sections/Command-Access-Price-List.png"
        width={560}
        quality={95}
        formats={["AUTO", "WEBP"]}
        alt="Command Access Price List"
        className='md:w-7/12 mx-auto'
      />

      <p className='text-white font-semibold mt-6'>November 21st, 2022 - The price lists will be available electronically & the hard copy shortly after. You can sign up for either or both below.</p>

      <PriceListSection />

      <p className='text-white font-semibold mt-6'>To ensure our ability to support customers and supply products to jobs, Command reserves the right to review, adjust or not accept large future-dated orders. All Command quotes are valid for 45 days.</p>

      <p className='text-white font-semibold mt-8'>If you have a project that requires price protection beyond January 3rd, 2023. Please get in touch with the CSR department to establish pricing for an extension.</p>

      <p className='text-white font-semibold mt-8'>We are excited to share all the new things we are working on as 2023 gets underway!</p>

      <p className='text-white font-semibold mt-8'>Thank you for being a Command Access customer. We appreciate your support & look forward to being here to help with your electrified hardware needs in 2023 & beyond!</p>
    </div>
  </div>
)

export default PriceListDescSection