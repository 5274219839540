import React from "react"

import MainContainer from "../components/containers/MainContainer"
import HeadData from "../data/HeadData"
import PriceListDescSection from "../components/sections/PriceListDescSection"

const PLRequestPage = () => (
  <MainContainer>
    <HeadData
      title='Price List Request Form'
      description='Request for Price List'
    />

    <PriceListDescSection />
  </MainContainer>
)

export default PLRequestPage